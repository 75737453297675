import React from 'react'

const Gitlab = props => (
  <svg width={64} height={64} {...props} viewBox="0 0 64 64">
    <linearGradient
      id="prefix__gitlab__a"
      x1={15.555}
      x2={15.555}
      y1={8}
      y2={28}
      gradientUnits="userSpaceOnUse"
      spreadMethod="reflect"
    >
      <stop offset={0} stopColor="#6dc7ff" />
      <stop offset={1} stopColor="#e6abff" />
    </linearGradient>
    <path fill="url(#prefix__gitlab__a)" d="M16.045 8L8.682 28h13.745z" />
    <linearGradient
      id="prefix__gitlab__b"
      x1={48.445}
      x2={48.445}
      y1={8}
      y2={28}
      gradientUnits="userSpaceOnUse"
      spreadMethod="reflect"
    >
      <stop offset={0} stopColor="#6dc7ff" />
      <stop offset={1} stopColor="#e6abff" />
    </linearGradient>
    <path fill="url(#prefix__gitlab__b)" d="M47.955 8l-6.382 20h13.745z" />
    <linearGradient
      id="prefix__gitlab__c"
      x1={32}
      x2={32}
      y1={28}
      y2={58}
      gradientUnits="userSpaceOnUse"
      spreadMethod="reflect"
    >
      <stop offset={0} stopColor="#6dc7ff" />
      <stop offset={1} stopColor="#e6abff" />
    </linearGradient>
    <path fill="url(#prefix__gitlab__c)" d="M22.427 28L32 58l9.573-30z" />
    <linearGradient
      id="prefix__gitlab__d"
      x1={18.5}
      x2={18.5}
      y1={7}
      y2={59.236}
      gradientUnits="userSpaceOnUse"
      spreadMethod="reflect"
    >
      <stop offset={0} stopColor="#1a6dff" />
      <stop offset={1} stopColor="#c822ff" />
    </linearGradient>
    <path
      fill="url(#prefix__gitlab__d)"
      d="M32 59c-.209 0-.419-.065-.596-.196l-27-20a1.002 1.002 0 01-.343-1.149l11.046-30c.147-.399.504-.632.96-.654.428.01.802.289.932.696l15.954 50A1.001 1.001 0 0132 59zM6.197 37.643l23.908 17.71-14.126-44.275-9.782 26.565z"
    />
    <linearGradient
      id="prefix__gitlab__e"
      x1={45.5}
      x2={45.5}
      y1={7}
      y2={59.236}
      gradientUnits="userSpaceOnUse"
      spreadMethod="reflect"
    >
      <stop offset={0} stopColor="#1a6dff" />
      <stop offset={1} stopColor="#c822ff" />
    </linearGradient>
    <path
      fill="url(#prefix__gitlab__e)"
      d="M32 59a1.003 1.003 0 01-.953-1.304l15.954-50c.13-.407.504-.686.932-.696.391.024.813.255.96.654l11.046 30a1 1 0 01-.343 1.149l-27 20A.995.995 0 0132 59zm16.021-47.922L33.895 55.353l23.908-17.71-9.782-26.565z"
    />
    <linearGradient
      id="prefix__gitlab__f"
      x1={32}
      x2={32}
      y1={6.954}
      y2={59.189}
      gradientUnits="userSpaceOnUse"
      spreadMethod="reflect"
    >
      <stop offset={0} stopColor="#1a6dff" />
      <stop offset={1} stopColor="#c822ff" />
    </linearGradient>
    <path
      fill="url(#prefix__gitlab__f)"
      d="M32 59a.996.996 0 01-.789-.387l-23.318-30A1 1 0 018.682 27h46.637a1 1 0 01.789 1.614l-23.318 30A1.002 1.002 0 0132 59zM10.726 29L32 56.37 53.274 29H10.726z"
    />
  </svg>
)

export default Gitlab
