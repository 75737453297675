import React from 'react'
import './style.css'

const Avatar = ({src, alt}) => (
    <div className='avatar-container'>
        <img src={src} alt={alt} />
    </div>
)

export default Avatar
