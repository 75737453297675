import React from 'react'

import Github from '../Images/Github'
import Gitlab from '../Images/Gitlab'
import LinkedIn from '../Images/LinkedIn'
import Twitter from '../Images/Twitter'

import './style.css'

const Contacts = [
    {
        title: 'Github',
        icon: Github,
        src: 'https://github.com/banerjeesouvik'
    },
    {
        title: 'Gitlab',
        icon: Gitlab,
        src: 'https://gitlab.com/banerjeesouvik'
    },
    {
        title: 'LinkedIn',
        icon: LinkedIn,
        src: 'https://linkedin.com/in/banerjee-souvik'
    },
    {
        title: 'Twitter',
        icon: Twitter,
        src: 'https://twitter.com/_banerjeesouvik'
    }
]

const ContactSection = () => (
    <section className='contacts-conatiner'>
        {
            Contacts.map(({title, src, icon: Icon}) => <span key={title} className='contact-icon-container'>
                <a href={src} target='_blank' rel='noopener noreferrer'>
                    <Icon title={title} height='50' width='50' />
                </a>
            </span>)
        }
    </section>
)

export default ContactSection