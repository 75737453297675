import React from "react"
import SEO from "../components/seo"
import Layout from '../components/layout'
import Avatar from '../components/Avatar'
import Descreption from '../components/Description'
import ContactsSection from '../components/ContactsSection'

import AvatarImg from '../assests/Souvik_Banerjee.jpeg'

import '../styles/homepage.css'


function HomePage() {
  return (
  <div className='container'>
      <SEO
        title="Home | Souvik Banerjee"
      />
      <Layout>
        <div className='content'>
          <Avatar
              src={AvatarImg}
              alt='Souvik Banerjee'
          />
          <Descreption>
              Hello, I am <strong>Souvik Banerjee</strong>. I am a Web Developer. I love to code and explore new technologies.
              Apart from coding, I love travelling and reading books.
          </Descreption>
          <ContactsSection />
        </div>
      </Layout>
  </div>
)}


export default HomePage
