import * as React from "react"

function TwitterIcon (props) {
  return (
    <svg viewBox="0 0 64 64" width={64} height={64} {...props}>
      <linearGradient
        id="prefix_twitter_a"
        x1={32}
        x2={32}
        y1={16.25}
        y2={48.312}
        gradientUnits="userSpaceOnUse"
        spreadMethod="reflect"
      >
        <stop offset={0} stopColor="#6dc7ff" />
        <stop offset={1} stopColor="#e6abff" />
      </linearGradient>
      <path
        fill="url(#prefix_twitter_a)"
        d="M26.064 45.003c12.076 0 18.68-10.005 18.68-18.68 0-.284-.006-.567-.019-.849A13.363 13.363 0 0048 22.075a13.092 13.092 0 01-3.77 1.034 6.592 6.592 0 002.887-3.632 13.168 13.168 0 01-4.169 1.594 6.566 6.566 0 00-11.358 4.491c0 .515.058 1.016.17 1.497A18.638 18.638 0 0118.228 20.2a6.545 6.545 0 00-.889 3.3 6.559 6.559 0 002.922 5.465 6.52 6.52 0 01-2.974-.821l-.001.084a6.567 6.567 0 005.267 6.436 6.585 6.585 0 01-2.965.113 6.574 6.574 0 006.133 4.56 13.172 13.172 0 01-8.154 2.81c-.53 0-1.052-.03-1.566-.091a18.599 18.599 0 0010.063 2.947"
      />
      <linearGradient
        id="prefix_twitter_b"
        x1={32}
        x2={32}
        y1={5.25}
        y2={59.38}
        gradientUnits="userSpaceOnUse"
        spreadMethod="reflect"
      >
        <stop offset={0} stopColor="#1a6dff" />
        <stop offset={1} stopColor="#c822ff" />
      </linearGradient>
      <path
        fill="url(#prefix_twitter_b)"
        d="M32 58C17.663 58 6 46.337 6 32S17.663 6 32 6s26 11.663 26 26-11.663 26-26 26zm0-50C18.767 8 8 18.767 8 32s10.767 24 24 24 24-10.767 24-24S45.233 8 32 8z"
      />
    </svg>
  )
}

export default TwitterIcon
