import React from 'react'

const LinkedIn = props => (
  <svg width={64} height={64} {...props} viewBox="0 0 64 64">
    <linearGradient
      id="prefix__linkedin__a"
      x1={19}
      x2={19}
      y1={24.858}
      y2={49.041}
      gradientUnits="userSpaceOnUse"
      spreadMethod="reflect"
    >
      <stop offset={0} stopColor="#6dc7ff" />
      <stop offset={1} stopColor="#e6abff" />
    </linearGradient>
    <path
      fill="url(#prefix__linkedin__a)"
      fillRule="evenodd"
      d="M22 48V26h-6v22h6z"
      clipRule="evenodd"
    />
    <linearGradient
      id="prefix__linkedin__b"
      x1={19.382}
      x2={19.382}
      y1={15.423}
      y2={23.341}
      gradientUnits="userSpaceOnUse"
      spreadMethod="reflect"
    >
      <stop offset={0} stopColor="#6dc7ff" />
      <stop offset={1} stopColor="#e6abff" />
    </linearGradient>
    <path
      fill="url(#prefix__linkedin__b)"
      fillRule="evenodd"
      d="M19.358 23c2.512 0 4.076-1.474 4.076-3.554-.047-2.126-1.564-3.649-4.028-3.649-2.465 0-4.076 1.475-4.076 3.601 0 2.08 1.563 3.602 3.981 3.602h.047z"
      clipRule="evenodd"
    />
    <linearGradient
      id="prefix__linkedin__c"
      x1={37.386}
      x2={37.386}
      y1={14.125}
      y2={49.525}
      gradientUnits="userSpaceOnUse"
      spreadMethod="reflect"
    >
      <stop offset={0} stopColor="#6dc7ff" />
      <stop offset={1} stopColor="#e6abff" />
    </linearGradient>
    <path
      fill="url(#prefix__linkedin__c)"
      fillRule="evenodd"
      d="M26.946 48H34V35.911c0-.648.122-1.295.313-1.758.52-1.295 1.877-2.635 3.867-2.635 2.607 0 3.821 1.988 3.821 4.901V48h6V35.588c0-6.657-3.085-9.498-7.826-9.498-3.886 0-5.124 1.91-6.072 3.91H34v-4h-7.054c.095 2-.175 22-.175 22h.175z"
      clipRule="evenodd"
    />
    <linearGradient
      id="prefix__linkedin__d"
      x1={32}
      x2={32}
      y1={6.5}
      y2={57.5}
      gradientUnits="userSpaceOnUse"
      spreadMethod="reflect"
    >
      <stop offset={0} stopColor="#1a6dff" />
      <stop offset={1} stopColor="#c822ff" />
    </linearGradient>
    <path
      fill="url(#prefix__linkedin__d)"
      d="M50 57H14c-3.859 0-7-3.141-7-7V14c0-3.859 3.141-7 7-7h36c3.859 0 7 3.141 7 7v36c0 3.859-3.141 7-7 7zM14 9c-2.757 0-5 2.243-5 5v36c0 2.757 2.243 5 5 5h36c2.757 0 5-2.243 5-5V14c0-2.757-2.243-5-5-5H14z"
    />
  </svg>
)

export default LinkedIn
